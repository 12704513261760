<template>
  <div v-html="html"></div>
</template>

<script>
import { Remarkable } from 'remarkable';
import { linkify } from 'remarkable/linkify';

export default {
  props: ['content'],

  computed: {
    html() {
      return new Remarkable().use(linkify).render(this.content);
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  ::v-deep {
    img {
      max-width: 100%;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}
</style>
