<template>
  <div>
    <label v-if="label" :for="id">
      {{ label }}
      <span v-if="required" class="required">*</span>
    </label>

    <textarea
      class="form-control"
      v-bind="attrs"
      :id="id"
      :class="{ 'is-invalid': error }"
      :value="value"
      @input="$emit('input', $event.target.value)"
    ></textarea>

    <div class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
import BaseField from './BaseField';

export default {
  extends: BaseField,
};
</script>
